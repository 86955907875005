var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget widget-contact my-blue" }, [
    _c("h2", { staticClass: "contact-title  my-blue" }, [_vm._v("Contact")]),
    _c("ul", { staticClass: "large-text" }, [
      _c(
        "li",
        [
          _c("a-icon", { attrs: { type: "environment" } }),
          _vm._v(
            "\n      " + _vm._s(_vm.get(_vm.organisation, "adresse")) + "\n    "
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c("a-icon", { attrs: { type: "phone" } }),
          _vm._v(
            "\n      Tél . : " +
              _vm._s(_vm.get(_vm.organisation, "tel_fix")) +
              " " +
              _vm._s(_vm.get(_vm.organisation, "tel_mobile")) +
              "\n    "
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c("a-icon", { attrs: { type: "printer" } }),
          _vm._v(
            "\n      E-mail : " +
              _vm._s(_vm.get(_vm.organisation, "email")) +
              "\n    "
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }