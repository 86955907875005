<template>
  <div class="widget widget-contact my-blue">
    <h2 class="contact-title  my-blue">Contact</h2>
    <ul class="large-text">
      <li>
        <a-icon type="environment" />
        {{ get(organisation, "adresse") }}
      </li>
      <li>
        <a-icon type="phone" />
        Tél . : {{ get(organisation, "tel_fix") }} {{ get(organisation, "tel_mobile") }}
      </li>
      <li>
        <a-icon type="printer" />
        E-mail : {{ get(organisation, "email") }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
    props : {
        organisation : { type : Object}
    }
}
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
.my-blue {
  font-family: Sora, ubuntu, monospace;
  font-weight: 600;
  color: rgb(31 54 92);
}
</style>
